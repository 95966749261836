<template>
  <v-autocomplete
    v-model="activeUsersID"
    :loading="loading"
    :search-input.sync="search"
    :disabled="isUpdating"
    :items="users"
    filled
    chips
    color="secundary"
    :label="$t('workspace.users', locale)"
    item-text="Name"
    item-value="ID"
    multiple
    @change="onChangeCustomer(activeUsersID)"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left v-if="data.item.Avatar">
          <v-img :src="data.item.Avatar"></v-img>
        </v-avatar>
        {{ data.item.Name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <!-- <template
        v-if="typeof data.item !== 'object'" 
      >
        <v-list-item-content v-text="data.item" />
      </template> -->
      <template
      >
        <v-list-item-content>
          <!--v-list-item-avatar v-if="data.item.Avatar">
            <img :src="data.item.Avatar">
          </v-list-item-avatar-->
          <v-list-item-title v-html="data.item.Name" />
          <v-list-item-subtitle v-html="data.item.ID" />
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  export default {
    props: {
      area: {
        type: String,
        required: false,
        default: null
      },
      folderID: {
        type: String,
        required: false,
        default: null
      },
      onChangeCustomer: {
        type: Function,
        required: true
      } 
    },
    data () {
      return {
        loading: false,
        search: null,
        activeUsersID: null,
        autoUpdate: true,
        isUpdating: false,
        name: 'Midnight Crew',
        users: [],
        // people: [
        //   { header: 'Group 1' },
        //   { name: 'Sandra Adams', group: 'Group 1', avatar: srcs[1] },
        //   { name: 'Ali Connors', group: 'Group 1', avatar: srcs[2] },
        //   { name: 'Trevor Hansen', group: 'Group 1', avatar: srcs[3] },
        //   { name: 'Tucker Smith', group: 'Group 1', avatar: srcs[2] },
        //   { divider: true },
        //   { header: 'Group 2' },
        //   { name: 'Britta Holt', group: 'Group 2', avatar: srcs[4] },
        //   { name: 'Jane Smith ', group: 'Group 2', avatar: srcs[5] },
        //   { name: 'John Smith', group: 'Group 2', avatar: srcs[1] },
        //   { name: 'Sandra Williams', group: 'Group 2', avatar: srcs[3] },
        // ],
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      folderID (newItem, oldItem) {
        if (newItem !== oldItem) this.handleGetActiveUser ()
      },
      search () {
        if (!this.loading) {
          setTimeout(() => {
            this.loading = true
            this.handleGetUserFiltered()
          }, 1000) // 1 sec delay
        }
      },
      isUpdating (val) {
        if (val) {
          setTimeout(() => (this.isUpdating = false), 3000)
        }
      },
    },
    mounted () {
      this.handleGetActiveUser()
    },
    methods: {
      handleGetActiveUser () {
        this.loading = true
        this.activeUsersID = null
        // obtenemos los usuaros conectados al workspaces
        if (this.folderID) api.getAll ('dfiles', `v1/private/folders/${this.folderID}/customers/`, 0, 10000, '')
          .then(response => {
            this.activeUsersID = response.map(item => { return item.CustomerID })
            this.onChangeCustomer(this.activeUsersID)
            this.handleGetUserFiltered()
          })
        else this.handleGetUserFiltered()
      },
      handleGetUserFiltered () {
        // obtenermos los usuarios filtrados y conectados
        if (this.activeUsersID || (this.search && this.search.length > 0)) {
          const filters = {
            activeUsersID: this.activeUsersID,
            search: this.search
          }
          api.getActiveData ('dfiles', 'v1/private/customers/active-and-filtered', filters)
          .then(response => {
            this.users = response.data
            this.loading = false
          })
        } else {
          this.users = []
          this.loading = false
        }
      },
      remove (item) {
        const index = this.activeUsersID.indexOf(item.ID)
        if (index >= 0) this.activeUsersID.splice(index, 1)
      },
    },
  }
</script>
